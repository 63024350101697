<template>
  <div class="h-slide about">
    <!-- <img src="../../assets/about.jpg" class="img-fluid" alt="" /> -->
    <div class="overlay"></div>
    <div class="container content">
      <div class="watermark-container">
        <img
          src="../../assets/logo-watermark.png"
          class="watermark img-fluid"
          alt="LUSO Solutions"
        />
      </div>
      <div class="row h-100">
        <div class="d-flex align-items-center">
          <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
            <h2 class="title text-center">
              <span class="title-content">About us</span>
            </h2>
            <p>
              LUSO was inspired by the Chaka Bantu language which means
              innovation. The company was started to provide innovative
              solutions to the challenges that are hindering the FULL POTENTIAL
              of our clients.
            </p>
            <p>
              We aim to use innovative latest technologies to make solutions
              simple to use but robust, reliable & scalable with high
              availability.
            </p>
            <p>
              The Founders have been in the IT industries for more than 20
              years.
            </p>
            <p>
              We let our products & solutions speak for themselves.
            </p>
            <p>
              We thrive to make our products & solutions cost effective and make
              sure they add value to our clients businesses.
            </p>
            <p>
              Our core message is building a long-term business partnership,
              real relationship, reliable services, quality assurance and best
              to nothing customer support and care.
            </p>
            <p>Welcome to LUSO Solutions!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //
    };
  },
};
</script>

<style scoped>
.about {
  background: var(--blue-color);
}
.about .overlay {
  background: linear-gradient(white, transparent);
}
</style>
