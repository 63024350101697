<template>
  <div class="h-slide portifolio">
    <!-- <img src="../../assets/about.jpg" class="img-fluid" alt="" /> -->
    <div class="overlay"></div>
    <div class="container content">
      <div class="watermark-container">
        <img
          src="../../assets/logo-watermark.png"
          class="watermark img-fluid"
          alt="LUSO Solutions"
        />
      </div>
      <div class="row h-100">
        <div class="d-flex align-items-center">
          <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
            <h2 class="title text-center">
              <span class="title-content">Portifolio</span>
            </h2>
            <div class="row">
              <div class="col-md-8" style="border-right: 1px dashed #ccc">
                <div class="row">
                  <div class="col-12"><b>Our Clients</b></div>
                  <hr />
                  <div
                    v-for="client in clients"
                    class="col-lg-12 mb-3 service"
                    :key="client.id"
                  >
                    <a :href="client.website" target="_blank">
                      <font-awesome-icon
                        icon="angle-right"
                        class="me-2"
                      ></font-awesome-icon>
                      {{ client.name }}
                    </a>
                    <br />
                    <small
                      v-for="service in client.services"
                      class="text-black-50"
                      :key="service"
                    >
                      <span class="me-3">- {{ service }}</span>
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-12"><b>Partners</b></div>
                  <hr />
                  <div
                    v-for="partner in partners"
                    class="col-lg-12 mb-3 service"
                    :key="partner.id"
                  >
                    <a :href="partner.website" target="_blank">
                      <font-awesome-icon icon="angle-right"></font-awesome-icon>
                      {{ partner.name }}
                    </a>
                    <br />
                    <small class="text-black-50">{{
                      partner.description
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["clients", "partners"],
  data() {
    return {};
  },
};
</script>
