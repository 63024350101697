<template>
  <div class="h-slide services">
    <!-- <img src="../../assets/about.jpg" class="img-fluid" alt="" /> -->
    <div class="overlay"></div>
    <div class="container content">
      <div class="watermark-container">
        <img
          src="../../assets/logo-watermark.png"
          class="watermark img-fluid"
          alt="LUSO Solutions"
        />
      </div>
      <div class="row h-100">
        <div class="d-flex align-items-center">
          <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
            <h2 class="title text-center">
              <span class="title-content">Services & Solutions</span>
            </h2>
            <div class="row">
              <div
                v-for="service in services"
                class="col-lg-6 mb-3 service"
                :key="service.id"
              >
                <font-awesome-icon
                  icon="cube"
                  class="me-3 service-icon"
                ></font-awesome-icon
                >{{ service.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["services"],
  data() {
    return {
      //
    };
  },
};
</script>

<style scoped>
@media screen and (min-width: 768px) {
  h2 {
    color: white;
    text-shadow: 0 1px 2px #333;
  }
  .h-slide .overlay {
    background: linear-gradient(transparent, white);
  }
  .services {
    background: var(--blue-color);
  }
  .service {
    color: #333;
    font-size: 20px;
    text-shadow: 0 1px 2px #ccc;
    font-family: var(--thin-fonts);
  }
}
@media screen and (max-width: 768px) {
  h2 {
    color: white;
    text-shadow: 0 1px 2px #333;
  }
  .h-slide .overlay {
    background: linear-gradient(transparent, white);
  }
  .services {
    background: var(--blue-color);
  }
  .services .service .service-icon {
    display: none;
  }
  .service {
    color: #333;
    font-size: 20px;
    text-shadow: 0 1px 2px #ccc;
    font-family: var(--thin-fonts);
  }
}
</style>
