<template>
    <div class="h-slide top">
        <img src="../../../assets/sky.jpg" class="slide-bg" alt="Sky is the limit" />
        <div class="overlay"></div>
        <div class="container content">
            <div class="watermark-container">
                <img src="../../../assets/logo-watermark.png" class="watermark img-fluid" alt="LUSO Solutions" />
            </div>
            <section>
                <div class="d-flex flex-column flex-md-row align-items-center mb-1">
                    <div style="max-width: 100px" class="me-md-5"><img src="../../../assets/logo.png" class="img-fluid"
                            alt="LUSO Solutions" /></div>
                    <div class="mt-3 mt-md-5">
                        <h1>COMING PROFESSIONAL TRAINING</h1>
                        <h3 class="mb-5">WINDOWS & LINUX SERVERS ADMINISTRATION WORKSHOP</h3>
                    </div>
                </div>
                <div class="description mb-5 d-none d-md-block">This is five days intensive workshop that will transform
                    your skills to master
                    the
                    adminstration of your corporate Windows & Linux servers
                </div>
                <div>for <strong>TSh 1,500,000</strong></div>
                <div class="mb-5" style="font-size: 18px;">includes Tutorials, Hands-on labs, Meals & Certifications</div>
                <div class="row details text-center mt-0 mt-md-5-">
                    <div class="col-4 px-md-5">
                        <font-awesome-icon icon="calendar-alt" class="fa-2x mb-3 service-icon"></font-awesome-icon>
                        <div>8th to 12th <br> May 2023</div>
                    </div>
                    <div class="col-4 px-md-5">
                        <font-awesome-icon :icon="['far', 'clock']" class="fa-2x mb-3 service-icon"></font-awesome-icon>
                        <div>8AM to 5PM <br>Everyday</div>
                    </div>
                    <div class="col-4 px-md-5">
                        <font-awesome-icon icon="map-marker-alt" class="fa-2x mb-3 service-icon"></font-awesome-icon>
                        <div>Arusha, Tanzania</div>
                    </div>
                </div>
                <a href="mailto:hello@luso.solutions?subject=Re:Servers Training" class="reserve-a-seat text-center">RESERVE
                    YOUR SEAT NOW</a>
                <div class="contacts d-flex flex-row justify-content-between px-3 py-2 py-md-1">
                    <a href="mailto:hello@luso.solutions?subject=Re:Servers Training"
                        class="d-flex align-items-center">hello@luso.solutions</a>
                    <a href="tel:+255 764 021 233" class="d-flex align-items-center">+255 764 021 233</a>
                </div>
                <span class="scroll text-center d-none d-md-inline-flex flex-column">
                    <font-awesome-icon icon="caret-up" class="caret-up w-100 fa-2x"></font-awesome-icon>
                    <span class="scroll-text thin-fonts">SCROLL</span>
                    <font-awesome-icon icon="caret-down" class="caret-down w-100 fa-2x"></font-awesome-icon>
                </span>
                <div class="scroll mt-4 d-md-none">
                    <div class="point1 mb-2"></div>
                    <div class="point2"></div>
                </div>
            </section>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            //
        };
    },
};
</script>
  
<style scoped>
@media screen and (min-width: 768px) {

    .content {
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
        color: var(--blue-color);
        font-size: 5vw;
        display: inline-block;
        text-shadow: 0 1px 1px #fff;
    }

    .content section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-shadow: 0 1px 3px #000;
    }

    .content section .description {
        font-size: 18px;
        width: 70%;
        text-align: center;
    }

    .content section .details {
        font-size: 24px;
    }

    .content section a.reserve-a-seat {
        font-size: 30px;
        color: white;
        background: red;
        padding: 4px;
        text-decoration: none;
        margin-top: 50px;
        text-shadow: none;
        border: 1px solid white;
        border-radius: 12px 12px 0 0;
        transition: all .3s ease;
        width: 420px;
    }

    .content section a.reserve-a-seat:hover {
        background: orange;
        transform: scale(1.05);
        border-bottom: 1px solid white;
        box-shadow: 0 1px 3px 0 #000;
        border-radius: 12px;
    }

    .content section .contacts {
        font-size: 16px;
        /* margin-top: 15px; */
        border: 1px solid white;
        border-top: none;
        border-radius: 0 0 12px 12px;
        text-shadow: none;
        width: 420px;
        background: royalblue;
    }

    .content section .contacts a {
        color: white;
        text-decoration: none;
        transition: all .3s ease;
    }

    .content section .contacts a:hover {
        color: #ccc;
    }

    .content .scroll {
        position: absolute;
        bottom: 3%;
        right: 3%;
        font-size: 20px;
        color: #333;
        text-shadow: none;
    }

    .content .scroll .caret-up {
        animation: scroll-caret-up 1s infinite ease-in-out;
    }

    .content .scroll .caret-down {
        animation: scroll-caret-down 1s infinite ease-in-out;
    }
}

@media screen and (max-width: 768px) {
    .content {
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
        color: var(--blue-color);
        font-size: 5vw;
        display: inline-block;
        text-shadow: 0 1px 1px #fff;
    }

    .content section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-shadow: 0 1px 3px #000;
    }

    .content section h1 {
        font-size: 24px;
    }

    .content section h3 {
        font-size: 18px;
    }

    .content section .description {
        font-size: 18px;
        width: 70%;
        text-align: center;
    }

    .content section .details {
        font-size: 18px;
    }

    .content section a.reserve-a-seat {
        font-size: 26px;
        color: white;
        background: orange;
        padding: 12px;
        text-decoration: none;
        margin-top: 50px;
        text-shadow: none;
        border: 1px solid white;
        border-radius: 12px;
        transition: all .3s ease;
        box-shadow: 0 1px 3px 0 #000;
        transform: scale(1.05);
        width: 380px;
    }

    .content section a.reserve-a-seat:hover {
        background: orangered;
        border-bottom: 1px solid white;
    }

    .content section .contacts {
        font-size: 18px;
        border: 1px solid white;
        border-top: none;
        border-radius: 0 0 12px 12px;
        text-shadow: none;
        width: 380px;
        background: royalblue;
    }

    .content section .contacts a {
        color: white;
        text-decoration: none;
        transition: all .3s ease;
    }

    .content section .contacts a:hover {
        color: #ccc;
    }

    .content section .scroll {
        border: 1px solid white;
        height: 100px;
        width: 60px;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .content section .scroll .point1 {
        border: 1px solid white;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-top: -20px;
    }

    .content section .scroll .point2 {
        border: 1px solid white;
        border-radius: 100px;
        width: 10px;
        height: 20px;
        position: relative;
        animation: 1.5s infinite swing;
    }
}

@keyframes swing {
    0% {
        margin-top: 0;
    }

    50% {
        margin-top: 15px;
    }

    100% {
        margin-top: 0;
    }
}
@keyframes scroll-caret-up {
  0% {
    transform: translateY(0);
  }
  59% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes scroll-caret-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
  