import Vue from "vue";
import App from "./App.vue";
import Vuelidate from "vuelidate";
import jQuery from "jquery";
import Axios from "axios";
import VueToastNotification from "vue-toast-notification";
import VueTelInput from "vue-tel-input";

// importing FontAwesomeIcon icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faTimes,
  faPhone,
  faCheck,
  faUnlock,
  faHeadset,
  faCodeBranch,
  faAward,
  faUserGraduate,
  faList,
  faBars,
  faArrowLeft,
  faAngleRight,
  faCircleNotch,
  faCheckCircle,
  faSave,
  faSpinner,
  faCube,
  faPaperPlane,
  faPhoneAlt,
  faCaretUp,
  faCaretDown,
  faCalendarAlt,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";
import {
  faEnvelope,
  faEye,
  faUserCircle,
  faClock,
  faBuilding,
  faCommentAlt,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faTimes,
  faEnvelope,
  faPhone,
  faEye,
  faCheck,
  faUnlock,
  faHeadset,
  faCodeBranch,
  faAward,
  faUserGraduate,
  faList,
  faUserCircle,
  faBars,
  faArrowLeft,
  faAngleRight,
  faClock,
  faCircleNotch,
  faSpinner,
  faCheck,
  faCheckCircle,
  faSave,
  faCube,
  faBuilding,
  faCommentAlt,
  faPaperPlane,
  faPhoneAlt,
  faCaretUp,
  faCaretDown,
  faCalendarAlt,
  faMapMarkerAlt
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.prototype.$jquery = jQuery;
Vue.prototype.$axios = Axios;

Vue.use(VueToastNotification, { duration: "3500", position: "bottom" });
Vue.use(VueTelInput);
Vue.use(Vuelidate);

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

new Vue({
  render: (h) => h(App),
}).$mount("#app");
