<template>
  <div class="h-slide contacts">
    <img src="../../assets/contacts.jpg" class="slide-bg" alt="" />
    <div class="overlay"></div>
    <div class="container content">
      <div class="watermark-container">
        <img
          src="../../assets/logo-watermark.png"
          class="watermark img-fluid"
          alt="LUSO Solutions"
        />
      </div>
      <div class="row h-100">
        <div class="d-flex align-items-center">
          <div
            class="col-md-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2 py-4"
          >
            <h2 class="title text-center">
              <span class="title-content">Contact us</span>
            </h2>
            <form ref="contactForm" id="contactForm">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="name"
                    ><font-awesome-icon
                      :icon="['far', 'user-circle']"
                      class="me-2"
                    ></font-awesome-icon>
                    Enter your Full Name</label
                  >
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="form-control form-control-lg"
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <label for="email"
                    ><font-awesome-icon
                      :icon="['far', 'envelope']"
                      class="me-2"
                    ></font-awesome-icon
                    >Enter your Email Address
                    <small class="text-black-50"
                      >(We won't spam you)</small
                    ></label
                  >
                  <input
                    type="email"
                    name="email"
                    id="email"
                    class="form-control form-control-lg"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="company"
                    ><font-awesome-icon
                      :icon="['far', 'building']"
                      class="me-2"
                    ></font-awesome-icon
                    >Enter the Company Name
                    <small class="text-black-50">( optional )</small></label
                  >
                  <input
                    type="text"
                    name="company"
                    id="company"
                    class="form-control form-control-lg"
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <label for="phone"
                    ><font-awesome-icon
                      icon="phone-alt"
                      class="me-2"
                    ></font-awesome-icon
                    >Enter Your phone Number
                    <small class="text-black-50">( optional )</small></label
                  >
                  <VueTelInput
                    v-bind="telInputOptions"
                    class="form-control form-control-lg p-0"
                  />
                </div>
              </div>
              <div class="mb-3">
                <label for="message"
                  ><font-awesome-icon
                    :icon="['far', 'comment-alt']"
                    class="me-2"
                  ></font-awesome-icon
                  >Message / Requirements -- Drop us a line below</label
                >
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="4"
                  class="form-control form-control-lg"
                  placeholder="Hi LUSO,..."
                ></textarea>
              </div>
              <div class="row">
                <div class="col-7 pt-3">
                  <label>
                    <input type="checkbox" checked /><span class="ms-2"
                      >Please send me periodic Newsletters</span
                    >
                  </label>
                </div>
                <div class="col-5 d-flex">
                  <button
                    @click.prevent="sendData"
                    class="btn btn-dark flex-grow-1 py-3"
                  >
                    <font-awesome-icon
                      icon="paper-plane"
                      class="me-2"
                    ></font-awesome-icon>
                    SEND
                  </button>
                  <button
                    @click.prevent="clearForm"
                    class="d-none d-md-inline-block btn btn-danger ms-2"
                  >
                    <font-awesome-icon
                      icon="times"
                      class="me-2"
                    ></font-awesome-icon
                    >Clear
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import { required, email } from "vuelidate/lib/validators";

export default {
  props: ["services"],
  components: {
    VueTelInput,
  },
  data() {
    return {
      sender: {
        name: "",
        email: "",
        services: [],
        message: "",
      },
      telInputOptions: {
        mode: "international",
        defaultCountry: "",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "",
        validCharactersOnly: true,
        required: false,
        enabledCountryCode: true,
        preferredCountries: [],
        onlyCountries: ["TZ", "KE", "UG", "RW", "BI", "SS"],
        ignoredCountries: [],
        autocomplete: "off",
        name: "phone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
    };
  },
  mounted() {
    //
  },
  validations: {
    sender: {
      name: { required },
      email: { required, email },
      message: { required },
    },
  },
  methods: {
    validateData() {
      this.sendData();
    },
    sendData() {
      let formData = new FormData(this.$refs.contactForm);
      this.$axios
        .post("http://mailer.run/api/mail", formData)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    },
    clearForm() {
      this.sender.name = "";
      this.sender.email = "";
      this.sender.services = [];
      this.sender.message = "";
      document.querySelector("#contactForm").reset();
    },
  },
};
</script>

<style scoped>
.h-slide .overlay {
  background: linear-gradient(white, transparent);
}

.telInputStyles input {
  border: 2px solid red;
}

.contacts {
  background: var(--blue-color);
}
</style>
