<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <Home />
  </div>
</template>

<script>
import Home from "./components/Home";

export default {
  name: "App",
  components: {
    Home,
  },
  data() {
    return {
      //
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Allison&family=Staatliches&family=Ubuntu+Condensed&display=swap");

:root {
  --grey-color: #555;
  --blue-color: rgb(58, 101, 219);
  --header-fonts: "Staatliches", cursive;
  --thin-fonts: "Ubuntu Condensed", sans-serif;
  --cursive-fonts: "Allison", cursive;
}
.header-fonts {
  font-family: var(--header-fonts);
}
.thin-fonts {
  font-family: var(--thin-fonts);
}
.cursive-fonts {
  font-family: var(--cursive-fonts);
}

/* all forms labels labels thin fonts */
form label {
  font-family: var(--thin-fonts);
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.title {
  font-family: var(--header-fonts);
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.3);
  position: relative;
  margin-bottom: 30px;
}
.title .title-content {
  background: var(--blue-color);
  color: white;
  padding: 5px 30px;
  border-radius: 100px;
  border: 1px solid #333;
  border-right-width: 4px;
  border-left-width: 4px;
}
.title::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  top: 50%;
  z-index: -1;
  background: linear-gradient(to right, transparent, #333, transparent);
}
/* Scrollbar Design */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: linear-gradient(
    to right,
    rgba(90, 90, 243, 1),
    rgba(90, 90, 243, 0.8),
    rgba(90, 90, 243, 1)
  );
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(
    to right,
    rgba(255, 50, 50, 0.99),
    rgba(184, 0, 0, 0.99),
    rgba(184, 0, 0, 0.99),
    rgba(184, 0, 0, 0.99),
    rgba(255, 50, 50, 0.99)
  );
  border-radius: 100px;
  box-shadow: 0 1px 2px 0 #000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}
</style>
