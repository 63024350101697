<template>
  <div class="wrapper">
    <!-- <top-slide /> -->
    <ccna />
    <about-us />
    <services :services="services" />
    <portifolio :clients="clients" :partners="partners" />
    <!-- <products /> -->
    <contacts :services="services" />
    <location />
  </div>
</template>

<script>
// import TopSlide from "./Home/TopSlide.vue";
import AboutUs from "./Home/About.vue";
import Services from "./Home/Services.vue";
import Portifolio from "./Home/Portifolio.vue";
// import Products from "./Home/Products.vue";
import Contacts from "./Home/Contacts.vue";
import Location from "./Home/Location.vue";

// additional & temporary components
import Ccna from "./Home/banners/Ccna.vue";

export default {
  components: {
    // TopSlide,
    AboutUs,
    Services,
    Portifolio,
    Contacts,
    Location,
    Ccna,
    // Products,
  },
  data() {
    return {
      services: [
        { id: 1, name: "Free ICT Consultations" },
        { id: 2, name: "Professional Cloud Services" },
        { id: 3, name: "Software Design & Development" },
        { id: 4, name: "Mobile Applications Development" },
        { id: 5, name: "APIs Development & Integrations" },
        { id: 6, name: "Websites Design & Development" },
        { id: 7, name: "Network Planning & Installations" },
        { id: 8, name: "Servers' Installs & Configurations" },
        { id: 9, name: "CyberSecurity Testing & Services" },
        { id: 10, name: "Professional ICT Training Sessions" },
        { id: 11, name: "Marketing Strategies & Executions" },
        { id: 12, name: "Search Engine & Social Media Marketing" },
      ],
      clients: [
        {
          id: 1,
          name: "TISPA - Tanzania Internet Service Providers Association",
          logo: "",
          website: "https://tispa.or.tz",
          services: ["Website Development", "Website Maintenance"],
        },
        {
          id: 2,
          name: "JRIIT - JR Institute Of Information Technology",
          logo: "",
          website: "https://jriit.ac.tz",
          services: ["Academic Information System Application Development"],
        },
        {
          id: 3,
          name: "Take Me to Tanzania",
          logo: "",
          website: "https://takemetotanzania.com",
          services: [
            "Logo Design",
            "Promotional Materials Design",
            "Marketing Research & Strategies Development",
            "Website Design & Development",
          ],
        },
        {
          id: 4,
          name: "Kim Tours & Adventure Safaris",
          logo: "",
          website: "https://kim.tours",
          services: ["Website Re-Design & Development"],
        },
        {
          id: 5,
          name: "Bushland Adventure Travel",
          logo: "",
          website: "https://bushlandsafaris.com",
          services: ["Logo Design", "Website Design & Development"],
        },
        // {
        //   id: 5,
        //   name: "Wild Tanzania Safaris",
        //   logo: "",
        //   website: "https://wild-tanzania-safaris.com",
        //   services: ["Logo Design", "Website Design & Development"],
        // },
        {
          id: 6,
          name: "Mc-Ellys Hotel Arusha",
          logo: "",
          website: "https://mcellyshotel.com",
          services: ["Website Design & Development", "Online Marketing"],
        },
      ],
      partners: [
        {
          id: 1,
          name: "TATO",
          logo: "tato.png",
          website: "https://tatotz.org",
          description:
            "Partners in Tourism Sector Development / CyberSecurity Trainings",
        },
        {
          id: 2,
          name: "Dropping Zone",
          logo: "",
          website: "https://droppingzone.co.tz",
          description: "Tourism Technologies Development Partner",
        },
        {
          id: 3,
          name: "CRDB Bank",
          logo: "",
          website: "https://crdbbank.com",
          description: "Online Payments Gateway partner",
        },
        {
          id: 4,
          name: "Beem Africa",
          logo: "",
          website: "https://beem.africa/",
          description: "SMS Alerts, Chatbots & Mobile Payments partner",
        },
      ],
    };
  },
};
</script>

<style>
html {
  scroll-snap-type: y mandatory;
  -webkit-scroll-snap-type: y mandatory;
}
.wrapper {
  height: 100vh;
}
.h-slide {
  scroll-snap-align: start;
  -webkit-snap-align: start;
  scroll-snap-stop: always;
  -webkit-scroll-snap-stop: always;
  position: relative;
  overflow: hidden;
  height: 100%;
}
@media screen and (min-width: 769px) {
  .h-slide img.slide-bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
  }
  .h-slide .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 90%;
    border-radius: 8px;
    padding: 10px 15px;
    overflow: hidden;
    box-shadow: 0 0 3px 0 #000;
  }

  @supports (backdrop-filter: blur(15px)) {
    .h-slide .content {
      backdrop-filter: blur(15px);
      -webkit-backdrop-filter: blur(15px);
    }
  }

  @supports not (backdrop-filter: blur(15px)) {
    .h-slide .content {
      background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.3));
    }
  }
  .h-slide .watermark-container {
    position: absolute;
    max-width: 150px;
    max-height: 150px;
    bottom: -30px;
    left: -30px;
    transform: rotate(-15deg);
    opacity: 0.3;
  }
  .h-slide .content .inner-content {
    position: relative;
    height: 100%;
  }
  .h-slide .overlay {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 768px) {
  .h-slide img.slide-bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: 100%;
  }
  .h-slide .content {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    padding: 30px 15px;
    overflow: hidden;
    text-align: center;
  }

  @supports (backdrop-filter: blur(15px)) {
    .h-slide .content {
      backdrop-filter: blur(15px);
      -webkit-backdrop-filter: blur(15px);
    }
  }

  @supports not (backdrop-filter: blur(15px)) {
    .h-slide .content {
      background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.3));
    }
  }
  .h-slide .watermark-container {
    position: absolute;
    max-width: 150px;
    max-height: 150px;
    bottom: -30px;
    left: -30px;
    transform: rotate(-15deg);
    opacity: 0.3;
  }
  .h-slide .content .inner-content {
    position: relative;
    height: 100%;
  }
  .h-slide .overlay {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
